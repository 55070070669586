import React, { useEffect, useState, useMemo } from 'react'
import { arrDateBanner } from '../../utils/createTime'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl, Link } from 'gatsby-plugin-intl-v6'
import BannerImg from './bannerImg'

interface MdxEdge {
  node: {
    slug: string
    frontmatter: {
      title: string
      date: string
      language: string
    }
  }
}

export default function Banner() {
  const intl = useIntl()
  const locale = intl.locale
  const [data, setData] = useState([])
  
  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx (
        filter: { slug: { glob: "announcements/*" } },
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            slug
            frontmatter {
              title
              date(formatString: "YYYY-MM-DD")
              language
            }
          }
        }
      }
    }
  `)

  const filteredData = useMemo(() => {
    const filtered = (allMdx?.edges || []).filter(
      (edge: any) => edge.node.frontmatter?.language === locale
    )
    return filtered.length ? filtered.slice(0, 2) : []
  }, [allMdx?.edges, locale])

  useEffect(() => {
    // const bannerUrl = newsEnvUrl + '/api/v1/home/banner/'
    // const fetchData = async () => {
    //   const res = await getRequest(bannerUrl)
    //   setData(res.data.result.banner)
    // }
    // fetchData()
  }, [])

  let bannerlist = [
    {
      title: intl.formatMessage({ id: 'AB Wallet' }),
      content: intl.formatMessage({ id: 'Decentralized digital wallet' }),
      url: '/abwallet/',
      isLink: true
    },
    {
      title: intl.formatMessage({ id: 'Get AB' }),
      content: intl.formatMessage({ id: 'Earned from the Ecosystem' }),
      url: '/getab/',
      isLink: true
    },
    {
      title: intl.formatMessage({ id: 'AB Explorer' }),
      content: intl.formatMessage({ id: 'Explorer & Analytics platform' }),
      url: 'https://explorer.ab.org/',
      isLink: false
    },
    // {
    //   title: intl.formatMessage({ id: 'NewBridge' }),
    //   content: intl.formatMessage({ id: 'Realize asset exchange' }),
    //   url: 'https://newbridge.network/',
    //   isLink: false
    // }
  ]
  return (
    <>
      <div className={'banner-pc container'}>
        <div className={'banner-centent'}>
          <div className={'banner-news'}>
            {filteredData.length > 0 ? (
              filteredData.map((edge: MdxEdge, index: number) => {
                const { frontmatter, slug } = edge.node
                return (
                  <p className="mb-2" key={index}>
                    <Link to={`/${slug}`}>
                      {frontmatter?.title}
                      {arrDateBanner(frontmatter?.date)}
                    </Link>
                  </p>
                )
              })
            ) : null}
            <div className={'banner-title'}>
              $AB
            </div>
            <div className={'banner-sub-title'}>
              {intl.formatMessage({ id: 'AB Project build a decentralised future, providing an efficient and secure infrastructure for DApps and diverse assets.' })}
            </div>
          </div>
        </div>
        <div className={'banner-link'}>
          {bannerlist.map((item, index) => {
            return (
              <div key={index}>
                {item.isLink == true ? (
                  <Link to={item.url}>
                    <span>{item.title}</span>
                    <i>{item.content}</i>
                  </Link>
                ) : (
                  <a href={item.url} target="_blank">
                    <span>{item.title}</span>
                    <i>{item.content}</i>
                  </a>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <div className={'banner-mobile-box'}>
        <div className={'banner-mobile container'}>
          <div className={'banner-title'}>
            $AB
          </div>
          <div className={'banner-sub-title'}>
            {intl.formatMessage({ id: 'AB Project build a decentralised future, providing an efficient and secure infrastructure for DApps and diverse assets.' })}
          </div>
          {filteredData.length > 0 ? (
              filteredData.map((edge: MdxEdge, index: number) => {
                const { frontmatter, slug } = edge.node
                return (
                  <p className="mb-2" key={index}>
                    <Link to={`/${slug}`}>
                      {frontmatter?.title}
                      {arrDateBanner(frontmatter?.date)}
                    </Link>
                  </p>
                )
              })
            ) : null}
        </div>
        <div className={'banner-mobile banner-mobile-news container'}>
          <div className={'banner-news'}>
            {data && data.length > 0
              ? data.map((item: any, index) => {
                  return (
                    <p className="mb-2" key={index}>
                      {data.length == 1 ? (
                        <Link to={item.url}>
                          {item.title}
                          {arrDateBanner(item.created_at)}
                        </Link>
                      ) : (
                        <Link to={item.url}>
                          {item.title}
                          {index !== 0 ? arrDateBanner(item.created_at) : null}
                        </Link>
                      )}
                    </p>
                  )
                })
              : null}
          </div>
          <div className={'banner-link'}>
            {bannerlist.map((item, index) => {
              return (
                <div key={index}>
                  {item.isLink == true ? (
                    <Link to={item.url}>
                      <span>{item.title}</span>
                      <i>{item.content}</i>
                    </Link>
                  ) : (
                    <a href={item.url} target="_blank">
                      <span>{item.title}</span>
                      <i>{item.content}</i>
                    </a>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
